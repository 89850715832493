body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

#root, body, html {
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.update-icon, .unsuspend-icon, .low-priority {
  color: #2196f3;
}

.delete-icon, .clear-inputs, .high-priority {
  color: #f44336;
}

.impersonate-icon, .download-icon, .create-icon, .set-wholesaler-icon, .map-product-icon, .money-icon {
  color: #00af9a;
}

.details-icon, .medium-priority {
  color: #ff9800;
}

.router-link {
  text-decoration: none;
  color: inherit;
}

.router-link-error {
  color: #f44336;
}

.router-link-underline:hover {
  text-decoration: underline;
}

.router-button {
  text-decoration: none;
  color: inherit;
}

.language-form-box {
  border: 1px solid #eaeff1;
  padding: 0 0.7rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.language-form-box-error {
  border: 1px solid #f44336;
}

.custom-details-paper {
  padding: 1rem;
  width: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 8px;
  background-color: #fff;
}

.p1 {
  padding: 1rem
}

.tooltip-donut {
  position: absolute;
  text-align: center;
  padding: .5rem;
  background: #FFFFFF;
  color: #313639;
  border: 1px solid #313639;
  border-radius: 8px;
  pointer-events: none;
  font-size: 1rem;
}

.legend {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: center;

  .legend-item {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    .legend-dot {
      width: 17px;
      height: 17px;
      border-radius: 12px;
    }

    text {
      margin-left: .5rem;
      font-size: 1rem;
    }
  }
}

.dashboard-list-item {
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.40);
  box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.40);
  transition: 0.2s;
}

.dashboard-list-item:hover {
  -webkit-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.80);
  box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.80);
}
